.ant-table-tbody > tr > td {
  word-wrap: break-word;
  word-break: break-all;
}
.side-menu .ant-menu-vertical .ant-menu-item,
.side-menu .ant-menu-vertical-left .ant-menu-item,
.side-menu .ant-menu-vertical-right .ant-menu-item,
.side-menu .ant-menu-inline .ant-menu-item,
.side-menu .ant-menu-vertical .ant-menu-submenu-title,
.side-menu .ant-menu-vertical-left .ant-menu-submenu-title,
.side-menu .ant-menu-vertical-right .ant-menu-submenu-title,
.side-menu .ant-menu-inline .ant-menu-submenu-title {
  margin-top: 0;
  margin-bottom: 0;
}
.side-menu .ant-menu-vertical .ant-menu-item:not(:last-child),
.side-menu .ant-menu-vertical-left .ant-menu-item:not(:last-child),
.side-menu .ant-menu-vertical-right .ant-menu-item:not(:last-child),
.side-menu .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  font-size: 100%;
  border: 0;
  vertical-align: baseline;
}
html,
body {
  font: 14px/1.42 "Microsoft Yahei", "Helvetica Neue", Arial, Helvetica, sans-serif, Lato;
}
a {
  cursor: pointer;
  color: inherit;
}
img {
  border: none;
  vertical-align: middle;
}
i {
  font-style: normal;
}
p {
  word-wrap: break-word;
  word-break: break-all;
}
ol,
ul,
li {
  list-style: none;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.clearfix:before {
  content: '';
  line-height: 0;
  display: table;
}
.clearfix:after {
  content: '';
  display: block;
  height: 0;
  font-size: 0;
  clear: both;
  overflow: hidden;
}
.clearfix {
  *zoom: 1;
}
.text-c {
  text-align: center;
}
.text-l {
  text-align: left;
}
.text-r {
  text-align: right;
}
.break-word {
  word-wrap: break-word;
  word-break: break-word;
}
.white-nowrap {
  white-space: nowrap;
}
.ant-spin-container > .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}
.handle-btn {
  margin: 3px;
}
.ant-table-wrapper.middle-table td {
  vertical-align: middle;
}
.manager-table-warp {
  margin: 0 10px;
}
.manager-table-warp .form-manager {
  border-bottom: 0;
  font-size: 13px;
  color: #333;
  padding: 8px 15px;
}
.manager-table-warp .form-table {
  font-size: 13px;
}
.search-form-wrap {
  padding: 10px 20px;
}
.red-light {
  color: #E51C23;
}
.align-bottom-thead th {
  vertical-align: bottom;
}
.handle-link {
  color: #1890ff;
  cursor: pointer;
}
.handle-link:hover {
  color: #40a9ff;
}
.left-paginTable .ant-table-pagination.ant-pagination {
  float: left;
}
.ant-btn + .ant-btn {
  margin-left: 15px;
}
.ant-btn-link + .ant-btn-link {
  margin-left: 0;
}
.fixed-frame {
  position: absolute;
  top: 56px;
  bottom: 0;
  left: 200px;
  right: 0;
  overflow: auto;
}
