html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    font-size: 100%;
    border: 0;
    vertical-align: baseline;
}
html, body {
	font: 14px/1.42 "Microsoft Yahei","Helvetica Neue",Arial,Helvetica,sans-serif,Lato;
}
a{
	cursor: pointer;
	color: inherit;
}
img{
	border: none;
	vertical-align: middle;
}
i{
	font-style: normal; 
}
p {
	word-wrap: break-word;
	word-break: break-all;
}
ol, ul, li {
	list-style: none;
}