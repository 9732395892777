.fl {
  float: left;
}
.fr {
  float: right;
}
.clearfix:before {
  content: '';
  line-height: 0;
  display: table;
}
.clearfix:after {
  content: '';
  display: block;
  height: 0;
  font-size: 0;
  clear: both;
  overflow: hidden;
}
.clearfix {
  *zoom: 1;
}
.text-c {
  text-align: center;
}
.text-l {
  text-align: left;
}
.text-r {
  text-align: right;
}
.break-word {
  word-wrap: break-word;
  word-break: break-word;
}
.white-nowrap {
  white-space: nowrap;
}
.ant-spin-container > .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}
.handle-btn {
  margin: 3px;
}
.ant-table-wrapper.middle-table {
  td {
    vertical-align: middle;
  }
}

.manager-table-warp {
  margin: 0 10px;
  .form-manager {
    border-bottom: 0;
    font-size: 13px;
    color: #333;
    padding: 8px 15px;
  }
  .form-table {
    font-size: 13px;
  }
}

.search-form-wrap {
  padding: 10px 20px;
}
.red-light {
  color: #E51C23;
}
.align-bottom-thead th {
  vertical-align: bottom;
}

.handle-link {
  color: #1890ff;
  cursor: pointer;
  &:hover {
      color: #40a9ff;
  }
}
.left-paginTable {
  .ant-table-pagination.ant-pagination {
    float: left;
  }
}

.ant-btn + .ant-btn {
  margin-left: 15px;
}
.ant-btn-link + .ant-btn-link {
  margin-left: 0;
}
