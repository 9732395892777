.side-menu {
    width: 200px;
    position: fixed;
    z-index: 999;
    top: 56px;
    bottom: 0;
    left: 0;
    width: 200px;
    overflow: auto;
    box-shadow: 0 4px 4px 2px #ddd;
    background: #fff;
}