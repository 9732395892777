@import './base/antd-variables.less';
@import './base/reset.less';
@import './base/common.less';

.fixed-frame {
    position: absolute;
    top: 56px;
    bottom: 0;
    left: 200px;
    right: 0;
    overflow: auto;
}